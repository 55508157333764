import { useHandleSignInCallback, useLogto } from "@logto/react";
import { atom, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import "./";
import PlaceholderRedirecting from "./components/placeholder/PlaceholderRedirecting";
import Authenticated from "./pages/Authenticated";
import Landing from "./pages/Landing";
import apiFetch from "./utils/apiFetch";

export const userDetailAtom = atom();

function App() {
    const setUserDetails = useSetAtom(userDetailAtom);
    const { getAccessToken, fetchUserInfo, isAuthenticated } = useLogto();
    const [userToken, setUserToken] = useState(undefined);

    useEffect(() => {
        const fetchToken = async () => {
            const token = await getAccessToken();
            setUserToken(token);
            apiFetch.userToken = token;
        };

        const fetchUser = async () => {
            const user = await fetchUserInfo();
            setUserDetails(user);
        };

        if (isAuthenticated) {
            fetchToken();
            fetchUser();
        }
    }, [isAuthenticated]);

    const { isLoading } = useHandleSignInCallback(() => {
        // redirect to remove pathname 'callback' and GET token
        window.location.href = window.location.origin;
    });

    // When it's working in progress
    if (isLoading) {
        return <PlaceholderRedirecting />;
    }
    return isAuthenticated && userToken ? <Authenticated /> : <Landing />;
}

export default App;
