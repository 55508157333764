import React from "react";

const ButtonCancel = ({ handleClick, children }) => {
    return (
        <button
            className="item-center flex gap-2 rounded-md border border-solid bg-red-300 px-2 font-display text-red-800 hover:bg-red-600 hover:text-white"
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClick();
            }}
        >
            {children}
        </button>
    );
};
export default ButtonCancel;
