import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import apiFetch from "../../utils/apiFetch";
import ButtonBlue from "../partials/components/ButtonBlue";
import ButtonCancel from "../partials/components/ButtonCancel";
import ButtonGray from "../partials/components/ButtonGray";
import { useTranslation } from "react-i18next";

const SettingsPrompt = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const history = useHistory();
    const [openSettings, setOpenSettings] = useState(false);

    const handleClickDeletePrompt = () => {
        apiFetch
            .call("/prompts/" + id, "DELETE")
            .then(() => history.push("/prompts"));
    };
    const handleClickEditPrompt = () => {
        history.push(`/edit-prompt/${id}`);
    };
    return openSettings ? (
        <>
            <ButtonBlue handleClick={handleClickEditPrompt}>
                {t("settings_prompts.edit_prompt")}
            </ButtonBlue>
            <ButtonCancel handleClick={handleClickDeletePrompt}>
                {t("settings_prompts.delete_prompt")}
            </ButtonCancel>
            <ButtonGray handleClick={() => setOpenSettings(false)}>
                {t("settings_prompts.close_settings")}
            </ButtonGray>
        </>
    ) : (
        <ButtonBlue handleClick={() => setOpenSettings(true)}>
            {t("settings_prompts.settings")}
        </ButtonBlue>
    );
};
export default SettingsPrompt;
