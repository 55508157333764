import {
    DocumentTextIcon,
    ListBulletIcon,
    MagnifyingGlassCircleIcon,
    PlusCircleIcon,
} from "@heroicons/react/20/solid";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ProviderList from "../components/dashboard/ProviderList";
import LayoutPage from "./LayoutPage";

const actions = [
    {
        title: "dashboard.title.create_prompt",
        href: "/create-prompt",
        icon: PlusCircleIcon,
        iconForeground: "text-blue-700",
        iconBackground: "bg-blue-50",
    },
    {
        title: "dashboard.title.list_prompts",
        href: "/prompts",
        icon: ListBulletIcon,
        iconForeground: "text-purple-700",
        iconBackground: "bg-purple-50",
    },
    {
        title: "dashboard.title.search_production",
        href: "/production-list",
        icon: MagnifyingGlassCircleIcon,
        iconForeground: "text-yellow-700",
        iconBackground: "bg-yellow-50",
    },
    {
        title: "dashboard.title.cceyes_doc",
        href: "https://docs.cceyes.eu/",
        external: true,
        icon: DocumentTextIcon,
        iconForeground: "text-sky-700 ",
        iconBackground: "bg-sky-50",
    },
];
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const Dashboard = () => {
    const { t } = useTranslation();
    return (
        <LayoutPage>
            <h2 className="mb-16 flex justify-center font-display text-2xl font-semibold">
                {t("dashboard.title.page")}
            </h2>
            <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
                {actions.map((action, actionIdx) => (
                    <div
                        key={action.title}
                        className={classNames(
                            actionIdx === 0
                                ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                                : "",
                            actionIdx === 1 ? "sm:rounded-tr-lg" : "",
                            actionIdx === actions.length - 2
                                ? "sm:rounded-bl-lg"
                                : "",
                            actionIdx === actions.length - 1
                                ? "rounded-bl-lg rounded-br-lg  sm:rounded-bl-none"
                                : "",
                            "group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset hover:bg-blue-50"
                        )}
                    >
                        <div>
                            <span
                                className={classNames(
                                    action.iconBackground,
                                    action.iconForeground,
                                    "inline-flex rounded-lg p-3 ring-4 ring-white"
                                )}
                            >
                                <action.icon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </span>
                        </div>
                        <div className="mt-8">
                            <h3 className="text-base font-semibold leading-6 text-gray-900">
                                {action.external ? (
                                    <a
                                        href={action.href}
                                        className="focus:outline-none"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {/* Extend touch target to entire panel */}
                                        <span
                                            className="absolute inset-0"
                                            aria-hidden="true"
                                        />
                                        {t(action.title)}
                                    </a>
                                ) : (
                                    <Link
                                        to={action.href}
                                        className="focus:outline-none"
                                    >
                                        {/* Extend touch target to entire panel */}
                                        <span
                                            className="absolute inset-0"
                                            aria-hidden="true"
                                        />
                                        {t(action.title)}
                                    </Link>
                                )}
                            </h3>
                        </div>
                        <span
                            className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                            aria-hidden="true"
                        >
                            <svg
                                className="h-6 w-6"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                            </svg>
                        </span>
                    </div>
                ))}
            </div>
            <ProviderList />
        </LayoutPage>
    );
};
export default Dashboard;
