import { InformationCircleIcon } from "@heroicons/react/20/solid";
import React from "react";

const InfoInfo = ({ title, description = "" }) => {
    return (
        <div className="mx-auto w-fit rounded-md bg-blue-50 p-4 ">
            <div className="flex items-center">
                <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-5 w-5 text-blue-400" />
                </div>
                <div className="ml-3">
                    {title && (
                        <h3 className="mb-2 h-4 text-sm font-medium text-blue-800">
                            {title}
                        </h3>
                    )}
                    <div className="text-sm text-blue-700">
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default InfoInfo;
