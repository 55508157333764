import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import apiFetch from "../../utils/apiFetch";
import { CONSTANT_USE_QUERY } from "../../utils/useFetch";
import PlaceholderPromptDetail from "../placeholder/PlaceholderPromptDetail";
import ButtonBlue from "./components/ButtonBlue";
import { useTranslation } from "react-i18next";
import LineProductionSmall from "./LineProductionSmall";

const PromptDetail = ({ prompt_id, title, text }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [open, setOpen] = useState(false);

    const dataQueryPromptDetail = useQuery(
        CONSTANT_USE_QUERY.PROMPT_DETAILS + prompt_id,
        () => apiFetch.call("/prompts/" + prompt_id)
    );

    const handleClickOpenPromptList = () => {
        setOpen(!open);
    };

    // if (dataQueryPromptDetail.isLoading) return <PlaceholderPromptDetail />;
    if (dataQueryPromptDetail.isError) return null;
    return (
        <>
            <section
                className="group relative flex cursor-pointer justify-between border-b pl-2 hover:bg-gray-50"
                onClick={handleClickOpenPromptList}
            >
                <div className="flex flex-col">
                    <div className="text-sm font-semibold leading-6 text-gray-900">
                        {title}
                    </div>
                    <div className="mb-1 mt-1 text-sm leading-5 text-gray-500">
                        {text}
                    </div>
                </div>
                <div className="flex items-center justify-end gap-2 p-3">
                    <ButtonBlue
                        className={clsx({
                            block: open,
                            "hidden group-hover:block": !open,
                        })}
                        handleClick={() => history.push(`/prompt/${prompt_id}`)}
                    >
                        {t("prompt_detail.button_detail")}
                    </ButtonBlue>
                    {open ? (
                        <ChevronUpIcon className="h-5 w-5 shrink-0" />
                    ) : (
                        <ChevronDownIcon className="h-5 w-5 shrink-0" />
                    )}
                </div>
            </section>
            <div
                className={clsx("overflow-x-hidden", {
                    "max-h-0": !open,
                    "max-h-[600px]": open,
                })}
            >
                {dataQueryPromptDetail?.data?.productions?.map((item) => {
                    return (
                        <LineProductionSmall
                            key={item.id}
                            productionDetail={item}
                            prompt_id={prompt_id}
                            refetchPromptDetail={dataQueryPromptDetail.refetch}
                        />
                    );
                })}
            </div>
        </>
    );
};
export default PromptDetail;
