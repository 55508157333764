const apiFetch = {
    userToken: undefined,
    call: function (url, method = "GET", body = undefined, noCache = false) {
        const request = {
            method,
            headers: {
                Authorization: `Bearer ${this.userToken}`,
                "X-Api-Key": process.env.REACT_APP_API_KEY,
                "Content-Type": "application/json",
            },
        };
        if (body) {
            request.body = JSON.stringify(body);
        }
        if (noCache) {
            request.headers["cache-control"] = "no-cache";
        }
        return fetch(process.env.REACT_APP_API_HOST + url, request)
            .then((resp) => {
                if (resp.status !== 200) {
                    throw "error";
                }
                return resp.json();
            })
            .catch((err) => {
                console.log("err =>", err);
                throw err;
            });
    },
};

export default apiFetch;
