import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LayoutPage from "../../pages/LayoutPage";

const PromptNotFound = () => {
    const { t } = useTranslation();
    return (
        <LayoutPage>
            <section className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
                <div className="text-center">
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                        {t("prompt_not_found.title")}
                    </h1>
                    <p className="mt-6 text-base leading-7 text-gray-600">
                        {t("prompt_not_found.text")}
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <Link
                            to="/prompts"
                            className="flex items-center gap-2 rounded-md border border-solid border-blue-600 bg-blue-600 p-1 font-display text-white hover:bg-sky-100 hover:text-blue-600"
                        >
                            {t("prompt_not_found.button_go_prompt_list")}
                        </Link>
                    </div>
                </div>
            </section>
        </LayoutPage>
    );
};
export default PromptNotFound;
