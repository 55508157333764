import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import apiFetch from "../utils/apiFetch";
import ErrorInfo from "./info/ErrorInfo";
import LineProduction from "./partials/LineProduction";
import SvgLoading from "./partials/SvgLoading";

const ListProductions = ({
    querySearch,
    addToPrompt,
    promptCall,
    hasResultsPrompt,
    listPrompts,
    showDelete = true,
    productionList,
}) => {
    const [querySearchDebounce] = useDebounce(querySearch, 300);
    const [dataProductions, setDataProductions] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [errorFetchingPrompt, setErrorFetchingPrompt] = useState(false);

    useEffect(() => {
        console.log("productionList =>", productionList);
        if (productionList?.length) setDataProductions(productionList);
    }, [productionList]);
    useEffect(() => {
        console.log("promptCall =>", promptCall);
        console.log(
            "querySearch querySearchDebounce ListProdu =>",
            querySearchDebounce
        );
        if (dataLoading && querySearch.length === 0) {
            setDataLoading(false);
        }
        if (
            querySearchDebounce !== querySearch &&
            !dataLoading &&
            querySearch.length > 0
        ) {
            setDataLoading(true);
        }
        if (
            querySearchDebounce &&
            querySearchDebounce.length > 0 &&
            querySearchDebounce === querySearch
        ) {
            apiFetch
                .call(
                    promptCall ? "/prompts" : "/productions/search",
                    "POST",
                    { query: querySearchDebounce, per_page: 50 },
                    true
                )
                .then((data) => {
                    console.log("data LIST PRODUC =>", data);
                    if (data === "error") {
                        setErrorFetchingPrompt(true);
                        return;
                    }
                    if (promptCall) {
                        setDataProductions(data?.productions || []);
                        if (
                            data?.productions?.length > 0 &&
                            typeof hasResultsPrompt === "function"
                        ) {
                            hasResultsPrompt();
                        }
                    } else {
                        setDataProductions(data);
                    }
                    setDataLoading(false);
                });
        }
        return () => {};
    }, [querySearchDebounce, querySearch]);

    if (errorFetchingPrompt) {
        return (
            <div className="mt-5">
                <ErrorInfo description={t("error_info.default")} />
            </div>
        );
    }
    return (
        <div className="relative">
            {dataLoading && (
                <div className="absolute inset-0 z-50 flex flex-col justify-start gap-4 bg-white bg-opacity-90 p-6 text-center text-gray-500">
                    <p className="text-2xl font-bold">
                        {t("list_production.loading")}
                    </p>
                    <SvgLoading />
                </div>
            )}
            {querySearch &&
                querySearch.length > 0 &&
                dataProductions.length === 0 && (
                    <>
                        <p className="mb-6 flex justify-center text-xl text-gray-800">
                            {t("list_production.loading")}
                        </p>
                        <SvgLoading />
                    </>
                )}
            {(querySearch && querySearch.length > 0) ||
            dataProductions?.length > 0 ? (
                <section className="mt-5 w-full">
                    {dataProductions.map((item) => {
                        return (
                            <LineProduction
                                key={item.id}
                                addToPrompt={addToPrompt}
                                listPrompts={listPrompts}
                                productionDetail={item}
                                showDelete={showDelete}
                            />
                        );
                    })}
                </section>
            ) : (
                <>
                    <MagnifyingGlassIcon className=" m-auto w-52  text-gray-300" />
                    <p className="flex justify-center text-2xl text-gray-800">
                        {t("list_production.search")}
                    </p>
                </>
            )}
        </div>
    );
};
export default ListProductions;
