import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { initialStateFilterPrompt } from "../../pages/Prompt";
import ButtonGray from "../partials/components/ButtonGray";
import DropdownSelect from "../partials/components/DropdownSelect";

const initialStateListFilter = { type: {}, provider: {} };
const FiltersPrompt = ({ productionsArray, filter, setFilter }) => {
    const { t } = useTranslation();
    const [listFilter, setListFilter] = useState(initialStateListFilter);

    useEffect(() => {
        const listFilterComputed = {};
        listFilterComputed.type = {};
        listFilterComputed.provider = {};

        productionsArray.forEach((item) => {
            if (!listFilterComputed.provider[item.dataset.provider]) {
                listFilterComputed.provider[item.dataset.provider] = [];
            }
            if (
                !listFilterComputed.provider[item.dataset.provider].includes(
                    item.dataset.type
                )
            ) {
                listFilterComputed.provider[item.dataset.provider].push(
                    item.dataset.type
                );
            }
            if (!listFilterComputed.type[item.dataset.type]) {
                listFilterComputed.type[item.dataset.type] = [];
            }
            if (
                !listFilterComputed.type[item.dataset.type].includes(
                    item.dataset.provider
                )
            ) {
                listFilterComputed.type[item.dataset.type].push(
                    item.dataset.provider
                );
            }
        });
        setListFilter(listFilterComputed);
    }, [productionsArray]);

    const listFiltered = (list, filter) => {
        return filter.length
            ? Object.keys(list).filter((item) => list[item].includes(filter))
            : Object.keys(list);
    };
    const handleChangeFilter = (filter, name) => {
        setFilter((prevF) => ({
            ...prevF,
            [filter]: name === prevF[filter] ? "" : name,
        }));
    };

    return (
        <div className="mt-2 flex gap-4">
            <div className="flex items-center justify-start gap-4">
                <p> {t("filters_prompt.details.filter")} </p>
                <DropdownSelect
                    name={`${t("filters_prompt.provider")}${
                        filter.provider.length > 0 ? `: ${filter.provider}` : ""
                    }`}
                    list={listFiltered(listFilter.provider, filter.type)}
                    handleChangeFilter={(name) =>
                        handleChangeFilter("provider", name)
                    }
                    selected={filter.provider}
                />
                <DropdownSelect
                    name={`${t("filters_prompt.type")}${
                        filter.type.length > 0 ? `: ${filter.type}` : ""
                    }`}
                    list={listFiltered(listFilter.type, filter.provider)}
                    handleChangeFilter={(name) =>
                        handleChangeFilter("type", name)
                    }
                    selected={filter.type}
                />
            </div>
            {(filter.type.length > 0 || filter.provider.length > 0) && (
                <ButtonGray
                    handleClick={() => setFilter(initialStateFilterPrompt)}
                >
                    {t("filters_prompt.button.reset.filter")}
                </ButtonGray>
            )}
        </div>
    );
};
export default FiltersPrompt;
