import LogoCCEyes from "../partials/LogoCCEyes";

const PlaceholderRedirecting = () => {
    return (
        <section className="flex min-h-screen animate-pulse items-center justify-center">
            <LogoCCEyes className="h-1/2 max-h-screen w-1/2 opacity-50 grayscale" />
        </section>
    );
};

export default PlaceholderRedirecting;
