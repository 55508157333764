import chartSvg from "../../assets/images/chart.svg";
import { useTranslation } from "react-i18next";

const LogoCCEyes = ({ className }) => {
    const { t } = useTranslation();
    return (
        <img
            className={className}
            src={chartSvg}
            alt={t("landing.label.logo")}
        />
    );
};
export default LogoCCEyes;
