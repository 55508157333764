import clsx from "clsx";
import React from "react";

const ButtonGray = ({ className, handleClick, children }) => {
    return (
        <button
            className={clsx(
                className,
                "flex items-center gap-2 rounded-md border border-solid border-gray-600 bg-gray-600 px-2 font-display text-white hover:bg-gray-100 hover:text-gray-600"
            )}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClick();
            }}
        >
            {children}
        </button>
    );
};
export default ButtonGray;
