import React from "react";
import PlaceholderBackground from "./PlaceholderBackground";

const PlaceholderProductionLine = () => {
    return (
        <div>
            <div className="relative mt-3 flex items-center px-5 pb-6 sm:mt-0 sm:border-b-2 sm:border-solid sm:border-gray-200 sm:py-6">
                <div className="my-auto h-6 w-6 min-w-[1.5rem]"></div>
                <div className="flex flex-1 flex-row items-center gap-7">
                    <PlaceholderBackground className="h-32 w-24" />
                    <div className="flex-1">
                        <div className="mb-4 flex flex-col items-center justify-between sm:flex-row">
                            <PlaceholderBackground className="h-5 w-40" />
                            <div className="flex flex-row gap-2">
                                <PlaceholderBackground className="h-5 w-20" />
                                <PlaceholderBackground className="h-5 w-28" />
                            </div>
                        </div>

                        <PlaceholderBackground className="mb-1 h-5 w-full" />
                        <PlaceholderBackground className="mb-1 h-5 w-full" />
                        <PlaceholderBackground className="mb-1 h-5 w-full" />
                        <PlaceholderBackground className="mb-1 h-5 w-full" />
                        <PlaceholderBackground className="mb-1 h-5 w-full" />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PlaceholderProductionLine;
