import { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";

const RouteMiddleware = ({ path, render, ...rest }) => {
    const location = useLocation();

    useEffect(() => {
        window.gtag("event", "page_view", {
            page_path: location.pathname + location.search,
            page_location: window.location.href,
        });
    }, [location]);

    return <Route {...rest} path={path} render={render} />;
};

export default RouteMiddleware;
