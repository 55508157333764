import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import React from "react";

const WarnningInfo = ({ title, description = "" }) => {
    return (
        <div className="mx-auto w-fit rounded-md bg-orange-50 p-4 ">
            <div className="flex items-center">
                <div className="flex-shrink-0">
                    <ExclamationTriangleIcon className="h-5 w-5 text-orange-400" />
                </div>
                <div className="ml-3">
                    {title && (
                        <h3 className="mb-2 h-4 text-sm font-medium text-orange-800">
                            {title}
                        </h3>
                    )}
                    <div className="text-sm text-orange-700">
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default WarnningInfo;
