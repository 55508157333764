import BannerTop from "../components/landing/BannerTop";
import Description from "../components/landing/Description";
import Footer from "../components/landing/Footer";
import Header from "../components/landing/Header";
import ContactEnd from "../components/landing/ContactEnd";

const Landing = () => {
    return (
        <>
            <Header />
            <BannerTop />
            <Description />
            <ContactEnd />
            <Footer />
        </>
    );
};

export default Landing;
