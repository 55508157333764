import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import LineProduction from "../components/partials/LineProduction";
import PlaceholderPromptPage from "../components/placeholder/PlaceholderPromptPage";
import ExportJsonPrompt from "../components/prompt/ExportJsonPrompt";
import FiltersPrompt from "../components/prompt/FiltersPrompt";
import OpenCCEyesCatcher from "../components/prompt/OpenCCEyesCatcher";
import OpenTimeline from "../components/prompt/OpenTimeline";
import PromptNotFound from "../components/prompt/PromptNotFound";
import SettingsPrompt from "../components/prompt/SettingsPrompt";
import apiFetch from "../utils/apiFetch";
import { CONSTANT_USE_QUERY } from "../utils/useFetch";
import LayoutPage from "./LayoutPage";

export const initialStateFilterPrompt = {
    provider: "",
    type: "",
};
const Prompt = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const history = useHistory();
    const [filter, setFilter] = useState(initialStateFilterPrompt);
    useEffect(() => {
        console.log("id =>", id);
        if (!id) {
            history.push("/prompts");
        }
        setFilter(initialStateFilterPrompt);
    }, [id]);

    const dataPromptDetail = useQuery(
        CONSTANT_USE_QUERY.PROMPT_DETAILS + id,
        () => apiFetch.call(`/prompts/${id}`)
    );
    if (dataPromptDetail.isLoading) {
        return <PlaceholderPromptPage />;
    }
    if (dataPromptDetail.isError) {
        return <PromptNotFound />;
    }

    const filteredProduction = (item) => {
        if (filter.type.length === 0 && filter.provider.length === 0)
            return true;

        if (filter.type.length && filter.provider.length) {
            return (
                filter.type.includes(item.dataset.type) &&
                filter.provider.includes(item.dataset.provider)
            );
        }

        return (
            filter.type.includes(item.dataset.type) ||
            filter.provider.includes(item.dataset.provider)
        );
    };
    console.log("dataPromptDetail =>", dataPromptDetail);
    return (
        <LayoutPage>
            <div className="sticky top-16 z-40 flex flex-col bg-white pb-4">
                <div className="flex items-start justify-between">
                    <div>
                        <h2 className="mb-4 font-display text-2xl font-normal">
                            {t("prompt.title")}{" "}
                            <span className="font-semibold">
                                {dataPromptDetail.data.name}
                            </span>
                        </h2>
                        <h3 className="font-display text-lg font-medium text-gray-700">
                            {t("prompt.query")} {dataPromptDetail.data.query}
                        </h3>
                    </div>
                    <div className="flex items-center gap-4">
                        <OpenCCEyesCatcher uuid={dataPromptDetail.data.uuid} />
                        <OpenTimeline uuid={dataPromptDetail.data.uuid} />
                        <ExportJsonPrompt
                            name={dataPromptDetail.data.name}
                            productionsArray={dataPromptDetail.data.productions}
                        />
                        <SettingsPrompt />
                    </div>
                </div>
                <FiltersPrompt
                    filter={filter}
                    setFilter={setFilter}
                    productionsArray={dataPromptDetail.data.productions}
                />
            </div>
            {dataPromptDetail.data.productions
                .filter(filteredProduction)
                .map((item) => {
                    return (
                        <LineProduction
                            key={item.id}
                            productionDetail={item}
                            prompt_id={dataPromptDetail.data.id}
                            refetchPromptDetail={dataPromptDetail.refetch}
                        />
                    );
                })}
        </LayoutPage>
    );
};
export default Prompt;
