import React from "react";
import PlaceholderBackground from "./PlaceholderBackground";

const PlaceholderPromptDetail = () => {
    return (
        <section className="flex justify-between border-b-2 hover:bg-gray-50">
            <div className="flex w-full flex-col">
                <div className="p-1 text-left font-display text-blue-600">
                    <PlaceholderBackground className="h-5 w-24" />
                </div>
                <div className="p-1 text-left font-display">
                    <PlaceholderBackground className="h-4 w-3/5" />
                </div>
            </div>
            <div className="flex items-center justify-end gap-2 p-3">
                <PlaceholderBackground className="h-5 w-5" />
            </div>
        </section>
    );
};
export default PlaceholderPromptDetail;
