import React from "react";
import ButtonBlue from "../partials/components/ButtonBlue";
import { useTranslation } from "react-i18next";

const ExportJsonPrompt = ({ name, productionsArray }) => {
    const { t } = useTranslation();
    const downloadFile = (fileName, myData) => {
        // create file in browser
        const json = JSON.stringify(myData, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);

        // create "a" HTLM element with href to file
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName.replaceAll(" ", "_") + ".json";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    };
    return (
        <ButtonBlue handleClick={() => downloadFile(name, productionsArray)}>
            {t("export-json-prompt.button.export-json")}
        </ButtonBlue>
    );
};
export default ExportJsonPrompt;
