import { Bars3Icon } from "@heroicons/react/20/solid";
import { useSetAtom } from "jotai";
import React from "react";
import { sidebarOpenAtom } from "./Authenticated";
import Footer from "../components/landing/Footer";

const LayoutPage = ({ children }) => {
    const setSidebarOpen = useSetAtom(sidebarOpenAtom);
    return (
        <div className="xl:pl-72">
            {/* Sticky search header */}
            <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-white/5 bg-white  px-4 shadow-sm sm:px-6  md:border-none md:shadow-none lg:px-8">
                <button
                    type="button"
                    className="-m-2.5 p-2.5 text-white xl:hidden"
                    onClick={() => setSidebarOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon
                        className="h-5 w-5 text-black"
                        aria-hidden="true"
                    />
                </button>

                <div className="flex flex-1 items-center justify-center gap-x-4 lg:gap-x-6"></div>
            </div>

            <main className="height-full-without-header mx-6 flex flex-col">
                <div className="flex-1">{children}</div>
                <Footer withoutPadding={true} />
            </main>
        </div>
    );
};
export default LayoutPage;
