import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import autosize from "autosize";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import SvgLoading from "../SvgLoading";

const TextareaCreatePrompt = ({
    handleChangeQueryPrompt,
    queryPrompt,
    queryPromptValidated,
    hasResultsPrompt,
    handleClickGeneratePrompt,
    namePrompt,
    handleChangeNamePrompt,
    handleClickSavePrompt,
    nameHasAlereadyUse,
    fetchingCreatePrompt,
    editMode,
}) => {
    const { t } = useTranslation();
    const labelButtonSave = editMode
        ? t("textarea.create_prompt.edit_prompt")
        : t("textarea.create_prompt.save_prompt");
    return (
        <div className="w-ful relative">
            <div className="overflow-hidden rounded-lg border border-gray-300 px-2">
                <label htmlFor="title" className="sr-only">
                    Name
                </label>
                <input
                    type="text"
                    name="title"
                    id="title"
                    className="mt-2.5 block w-full border-b border-gray-300 text-lg font-medium outline-none placeholder:text-gray-400"
                    placeholder={t("textarea.create_prompt.name.prompt")}
                    value={namePrompt}
                    onChange={(e) => {
                        handleChangeNamePrompt(e);
                    }}
                    disabled={editMode}
                />
                {nameHasAlereadyUse && !editMode && (
                    <div className="mt-2 flex w-fit items-center gap-2 rounded bg-orange-100 p-2">
                        <ExclamationTriangleIcon className="h-5 w-5 text-orange-500" />
                        <p className="text-sm text-orange-500">
                            {t("textarea.create_prompt.name_already_use")}
                        </p>
                    </div>
                )}
                <label htmlFor="description" className="sr-only">
                    Description
                </label>
                <textarea
                    rows={2}
                    name="description"
                    id="description"
                    className="mt-2 block w-full border-0 py-0 text-gray-900 outline-none placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    placeholder={t("textarea.create_prompt.query_prompt")}
                    value={queryPrompt}
                    onChange={(e) => {
                        handleChangeQueryPrompt(e);
                        autosize(e.target);
                    }}
                />

                {/* Spacer element to match the height of the toolbar */}
                <div aria-hidden="true">
                    <div className="h-px" />
                    <div className="py-2">
                        <div className="py-px">
                            <div className="h-9" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="absolute inset-x-px bottom-0">
                <div className="flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
                    <div className="flex items-center gap-4">
                        <p
                            className={clsx(
                                "text-sm",
                                queryPrompt.length < 800
                                    ? "text-gray-900"
                                    : "text-orange-600",
                                queryPrompt.length >= 1000 && "!text-red-600"
                            )}
                        >
                            {t("textarea.create_prompt.prompt.character")}{" "}
                            {queryPrompt.length} / 1000
                        </p>
                        {queryPrompt.length >= 1000 && (
                            <div className="flex items-center gap-2 rounded bg-red-100 p-2">
                                <ExclamationTriangleIcon className="h-5 w-5 text-red-500" />
                                <p className="text-sm text-red-500">
                                    {t(
                                        "textarea.create_prompt.limit_character"
                                    )}
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-shrink-0 gap-2">
                        {hasResultsPrompt && (
                            <button
                                type="submit"
                                className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600
                                    disabled:cursor-not-allowed  disabled:border-gray-300 disabled:bg-gray-200 disabled:text-gray-600"
                                disabled={
                                    (editMode && !hasResultsPrompt) ||
                                    (!editMode && namePrompt?.length === 0) ||
                                    fetchingCreatePrompt
                                }
                                onClick={handleClickSavePrompt}
                            >
                                {fetchingCreatePrompt ? (
                                    <div className="relative">
                                        <div className="absolute inset-0">
                                            <SvgLoading size="24" />
                                        </div>
                                        <div className="invisible">
                                            {labelButtonSave}
                                        </div>
                                    </div>
                                ) : (
                                    labelButtonSave
                                )}
                            </button>
                        )}
                        <button
                            type="submit"
                            className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-200 disabled:text-gray-600"
                            onClick={handleClickGeneratePrompt}
                            disabled={queryPrompt?.length === 0}
                        >
                            {queryPromptValidated.length > 0
                                ? "Refresh prompt"
                                : "Send prompt"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TextareaCreatePrompt;
