import clsx from "clsx";
import { useTranslation } from "react-i18next";
import coFundedEuPos from "../../assets/images/Co-Funded_EU_POS.png";
import creativeEuropeMedia from "../../assets/images/creative_europe_media.png";
import LogoCCEyes from "..//partials/LogoCCEyes";

const Footer = ({ withoutPadding = false }) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between">
            <div
                className={clsx(
                    "container relative mx-auto flex w-full flex-wrap gap-2",
                    withoutPadding ? "" : "px-4 sm:px-6 lg:px-8"
                )}
            >
                <div className="flex w-full items-center justify-between gap-8 md:flex-1 md:justify-start">
                    <div className="flex items-center font-display text-2xl text-slate-900">
                        <LogoCCEyes className=" mr-4 inline h-14 w-14" />
                        {t("footer.logo.cceyes")}
                    </div>
                    <a
                        className="font-display text-xl  text-slate-900 hover:text-blue-600"
                        href="https://cci-thrive.eu/"
                    >
                        {t("footer.link.cci_thrive")}
                    </a>
                </div>

                <img
                    className="mx-auto my-2 h-10"
                    src={creativeEuropeMedia}
                    alt="creative europe media"
                />
                <img
                    className="mx-auto my-2 h-11"
                    src={coFundedEuPos}
                    alt="creative europe media"
                />
                <p className="text-center text-slate-700">{t("footer.text")}</p>
            </div>
        </div>
    );
};

export default Footer;
