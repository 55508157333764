import ButtonBlue from "../partials/components/ButtonBlue";
import { useTranslation } from "react-i18next";

const OpenCCEyesCatcher = ({ uuid }) => {
    const { t } = useTranslation();
    return (
        <ButtonBlue
            handleClick={() => {
                const newUrl = new URL("https://cceyes-catcher.pages.dev");
                newUrl.searchParams.append("id", uuid);
                window.open(newUrl, "_blank");
            }}
        >
            {t("open-cceyes-catcher.button.data-visualisation")}
        </ButtonBlue>
    );
};
export default OpenCCEyesCatcher;
