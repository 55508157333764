import background from "../../assets/images/background.jpg";
import DescriptionText from "./partials/DescriptionText";
import { useTranslation } from "react-i18next";

const Description = () => {
    const { t } = useTranslation();
    return (
        <section
            id="features"
            aria-label="Features for running your books"
            className="relative overflow-hidden bg-blue-600 pb-8 pt-4 sm:py-16"
        >
            <img
                alt=""
                width="2245"
                height="1636"
                className="absolute left-1/2 top-1/2 max-w-none translate-x-[-44%] translate-y-[-42%]"
                src={background}
            />
            <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex max-w-2xl flex-col items-center gap-6 md:mx-auto xl:max-w-none">
                    <DescriptionText>
                        {t("landing.description.text.first")}
                    </DescriptionText>
                    <DescriptionText>
                        {t("landing.description.text.second")}
                    </DescriptionText>
                    <DescriptionText>
                        {t("landing.description.text.third")}
                    </DescriptionText>
                    <DescriptionText>
                        {t("landing.description.text.fourth")}
                    </DescriptionText>
                </div>
            </div>
        </section>
    );
};

export default Description;
