import { useTranslation } from "react-i18next";
import ButtonBlue from "../partials/components/ButtonBlue";

const OpenTimeline = ({ uuid }) => {
    const { t } = useTranslation();
    return (
        <ButtonBlue
            handleClick={() => {
                const newUrl = new URL(
                    "https://cci-thrive.art.centrica.it/cci-timeline/"
                );
                newUrl.pathname += uuid;
                window.open(newUrl, "_blank");
            }}
        >
            {t("open-cceyes-catcher.button.timeline")}
        </ButtonBlue>
    );
};
export default OpenTimeline;
