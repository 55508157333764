import { useQuery } from "react-query";

const useFetch = (call, queryKey) =>
    useQuery({
        queryKey,
        queryFn: () => call,
    });

export default useFetch;

export const CONSTANT_USE_QUERY = {
    PROMPT_LIST: "PROMPT_LIST",
    USER_INFOS: "USER_INFOS",
    PROMPT_DETAILS: "PROMPT_DETAILS",
    PROVIDER_LIST: "PROVIDER_LIST",
};
