import { atom } from "jotai";
import { Switch } from "react-router-dom";
import RouteMiddleware from "../components/router/RouterMiddleware";
import BrowseProduction from "./BrowseProduction";
import CreatePrompt from "./CreatePrompt";
import Dashboard from "./Dashboard";
import Layout from "./Layout";
import ListPrompt from "./ListPrompt";
import Prompt from "./Prompt";
import Erreur404 from "./Error404";

// function classNames(...classes) {
//     return classes.filter(Boolean).join(" ");
// }

export const sidebarOpenAtom = atom(false);

const Authenticated = () => {
    // console.log("apiFetch.userToken =>", apiFetch.userToken);
    // console.log('apiFetch("/users/me") =>', apiFetch.call("/users/me"));

    return (
        <Layout>
            <Switch>
                <RouteMiddleware
                    exact
                    path={"/"}
                    render={() => <Dashboard />}
                />
                <RouteMiddleware
                    exact
                    path={"/create-prompt"}
                    render={() => <CreatePrompt />}
                />
                <RouteMiddleware
                    exact
                    path={"/edit-prompt/:id"}
                    render={() => <CreatePrompt />}
                />
                <RouteMiddleware
                    exact
                    path="/prompts"
                    render={() => <ListPrompt />}
                />
                <RouteMiddleware
                    exact
                    path="/prompt/:id"
                    render={() => <Prompt />}
                />
                <RouteMiddleware
                    exact
                    path="/production-list"
                    render={() => <BrowseProduction />}
                />

                <RouteMiddleware path="/" render={() => <Erreur404 />} />
            </Switch>
        </Layout>
    );
};
export default Authenticated;
