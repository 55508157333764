import React, { useState } from "react";
import LayoutPage from "./LayoutPage";

import {
    MagnifyingGlassCircleIcon,
    XCircleIcon,
} from "@heroicons/react/20/solid";
import { useQuery } from "react-query";
import ListProductions from "../components/ListProductions";
import apiFetch from "../utils/apiFetch";
import { CONSTANT_USE_QUERY } from "../utils/useFetch";
import { useTranslation } from "react-i18next";

const BrowseProduction = () => {
    const { t } = useTranslation();
    const [querySearch, setQuerySearch] = useState("");

    const dataQueryPrompts = useQuery(CONSTANT_USE_QUERY.PROMPT_LIST, () =>
        apiFetch.call("/prompts")
    );
    const handleChangeSearchQuery = (e) => {
        if (typeof e?.target?.value === "string") {
            setQuerySearch(e.target.value);
        }
    };
    const handleClearQuerySearch = () => setQuerySearch("");
    console.log("dataQueryPrompts =>", dataQueryPrompts);
    return (
        <LayoutPage>
            <div className="sticky top-16 z-40 bg-white">
                <h2 className="mb-12 flex justify-center font-display text-2xl font-semibold">
                    {t("browse_production.title")}
                </h2>
                <div className="mb-8 flex w-full items-center gap-3 rounded-lg border border-solid  border-gray-300 px-3 py-2 text-gray-900  placeholder-gray-400">
                    <MagnifyingGlassCircleIcon
                        className=" h-6 w-6 shrink-0 text-blue-600"
                        aria-hidden="true"
                    />
                    <input
                        className="w-full outline-none"
                        type="text"
                        placeholder="Search a production"
                        onChange={handleChangeSearchQuery}
                        value={querySearch}
                    />
                    {querySearch && querySearch.length > 0 && (
                        <button onClick={handleClearQuerySearch}>
                            <XCircleIcon
                                className=" h-6 w-6 shrink-0 text-gray-400"
                                aria-hidden="true"
                            />
                        </button>
                    )}
                </div>
            </div>
            <ListProductions
                querySearch={querySearch}
                addToPrompt
                listPrompts={dataQueryPrompts}
            />
        </LayoutPage>
    );
};
export default BrowseProduction;
