import clsx from "clsx";
import React from "react";

const ButtonBlue = ({ className, handleClick, children }) => {
    return (
        <button
            className={clsx(
                className,
                "flex items-center gap-2 rounded-md border border-solid bg-blue-50 px-2 font-display text-sm text-gray-900 hover:bg-blue-200"
            )}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClick();
            }}
        >
            {children}
        </button>
    );
};
export default ButtonBlue;
