import React, { useEffect, useState } from "react";
import LayoutPage from "./LayoutPage";

import {
    MagnifyingGlassCircleIcon,
    XCircleIcon,
} from "@heroicons/react/20/solid";
import { useQuery } from "react-query";
import { Link } from "react-router-dom/cjs/react-router-dom";
import ErrorInfo from "../components/info/ErrorInfo";
import InfoInfo from "../components/info/InfoInfo";
import PromptDetail from "../components/partials/PromptDetail";
import PlaceholderPromptDetail from "../components/placeholder/PlaceholderPromptDetail";
import apiFetch from "../utils/apiFetch";
import { generateEmptyArray } from "../utils/tools";
import { CONSTANT_USE_QUERY } from "../utils/useFetch";
import { useTranslation } from "react-i18next";

const ListPrompt = () => {
    const { t } = useTranslation();
    const dataQueryPrompts = useQuery(CONSTANT_USE_QUERY.PROMPT_LIST, () =>
        apiFetch.call("/prompts")
    );

    const [querySearch, setQuerySearch] = useState("");
    const [openPrompt, setOpenPrompt] = useState(undefined);

    console.log("dataQueryPrompts =>", dataQueryPrompts);

    useEffect(() => {
        const url = new URL(window.location.href);
        console.log("url. =>", parseInt(url.searchParams.get("id")) || null);
        setOpenPrompt(parseInt(url.searchParams.get("id")) || null);
        return () => {};
    }, []);

    const handleChangeSearchQuery = (e) => {
        if (typeof e?.target?.value === "string") {
            setQuerySearch(e.target.value);
        }
    };
    const handleClearQuerySearch = () => setQuerySearch("");

    const renderListPrompt = () => {
        if (dataQueryPrompts.isLoading) {
            return generateEmptyArray(5).map((_) => (
                <PlaceholderPromptDetail />
            ));
        }
        if (dataQueryPrompts.isError) {
            return (
                <ErrorInfo description="Something Wrong, please try again." />
            );
        }
        if (dataQueryPrompts.data?.length === 0) {
            return (
                <InfoInfo
                    description={
                        <>
                            {t("list_prompt.info_info")}{" "}
                            <Link
                                to="/create-prompt"
                                className="font-bold underline"
                            >
                                {t("list_prompt.link_create_prompt_here")}
                            </Link>
                        </>
                    }
                />
            );
        }
        return dataQueryPrompts.data
            .filter(
                (item) =>
                    item.name
                        .toLowerCase()
                        .includes(querySearch.toLowerCase()) ||
                    item.query.toLowerCase().includes(querySearch.toLowerCase())
            )
            .map((item) => {
                return (
                    <PromptDetail
                        key={item.id}
                        prompt_id={item.id}
                        title={item.name}
                        text={item.query}
                        refetchPromptList={dataQueryPrompts.refetch}
                        defaultOpen={openPrompt === item.id}
                    />
                );
            });
    };
    console.log("openPrompt === item.id =>", openPrompt === 61);
    return (
        <LayoutPage>
            <div className="sticky top-16 z-40 bg-white">
                <h2 className="mb-12 flex justify-center font-display text-2xl font-semibold">
                    {t("list_prompt.your_list")}
                </h2>
                <div className="mb-8 flex w-full items-center gap-3 rounded-lg border border-solid  border-gray-300 px-3 py-2 text-gray-900  placeholder-gray-400">
                    <MagnifyingGlassCircleIcon
                        className=" h-6 w-6 shrink-0 text-blue-600"
                        aria-hidden="true"
                    />
                    <input
                        className="w-full outline-none"
                        type="text"
                        placeholder="Search in your prompts"
                        onChange={handleChangeSearchQuery}
                        value={querySearch}
                    />
                    {querySearch && querySearch.length > 0 && (
                        <button onClick={handleClearQuerySearch}>
                            <XCircleIcon
                                className=" h-6 w-6 shrink-0 text-gray-400"
                                aria-hidden="true"
                            />
                        </button>
                    )}
                </div>
            </div>
            <section className="mt-5 w-full pb-5">{renderListPrompt()}</section>
        </LayoutPage>
    );
};
export default ListPrompt;
