import { Menu, Transition } from "@headlessui/react";
import { FolderPlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholderImage from "../../assets/images/placeholderImage.png";
import apiFetch from "../../utils/apiFetch";
import ErrorInfo from "../info/ErrorInfo";
import SuccesInfo from "../info/SuccesInfo";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const LineProduction = ({
    prompt_id,
    listPrompts,
    productionDetail,
    refetchPromptDetail,
    addToPrompt,
    showDelete = true,
}) => {
    const { t } = useTranslation();
    const [showSucces, setShowSucces] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleClickDeleteProduction = (e) => {
        e.preventDefault();
        apiFetch
            .call(
                "/prompts/" + prompt_id + "/productions/" + productionDetail.id,
                "DELETE"
            )
            .then(() => refetchPromptDetail())
            .catch((e) => {
                console.log("e =>", e);
                refetchPromptDetail();
            });
    };
    const promptAlreadyHasProduction = listPrompts?.data
        ?.filter((prompt) =>
            prompt.productions_ids?.find((id) => id === productionDetail.id)
        )
        .filter(Boolean);

    const renderMetaProduction = () => {
        const { meta } = productionDetail;

        if (meta) {
            const metaPresent = [];
            ["reviews", "status", "extra"].map((item) => {
                if (meta[item]) metaPresent.push(item);
            });
            // console.log("metaPresent =>", metaPresent);
            if (metaPresent.length)
                return (
                    <p className="font-display text-sm">
                        <span className="font-display font-semibold text-blue-600">
                            {t("line_production.label.meta")}
                        </span>{" "}
                        {metaPresent.join(" / ")}
                    </p>
                );
        }
        return null;
    };

    const renderAlreadyPrompt = () => {
        if (promptAlreadyHasProduction?.length) {
            return (
                <p className="font-display font-normal">
                    <span className="font-display font-semibold text-blue-600">
                        {t("line_production.present_in_prompt")}
                    </span>{" "}
                    {promptAlreadyHasProduction
                        .map((item) => item.name)
                        .join(" / ")}
                </p>
            );
        }
        return null;
    };

    // console.log("listPrompts =>", listPrompts);

    const listPromptsEmptyProductions =
        addToPrompt &&
        listPrompts?.data?.filter(
            (itemPrompt) =>
                !promptAlreadyHasProduction.find(
                    (item) => item.id === itemPrompt.id
                )
        );

    // console.log("listPromptsEmptyProductions =>", listPromptsEmptyProductions);
    return (
        <>
            <div className="group relative mt-3 flex items-center px-5 pb-6 sm:mt-0 sm:border-b-2 sm:border-solid sm:border-gray-200 sm:py-6">
                <div className="my-auto h-6 w-6 min-w-[1.5rem]">
                    {!addToPrompt && showDelete && (
                        <Menu
                            as="div"
                            className="relative inline-block text-left"
                        >
                            <div>
                                <Menu.Button className="hidden items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 group-hover:flex">
                                    <span className="sr-only">
                                        {t("line_production.menu_button")}
                                    </span>
                                    <TrashIcon className="h-5 w-5 shrink-0 text-red-700" />
                                </Menu.Button>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute left-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg focus:outline-none">
                                    <div className="py-1">
                                        <form method="POST" action="#">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        type="submit"
                                                        className={classNames(
                                                            active
                                                                ? "bg-gray-100 text-gray-900"
                                                                : "text-gray-700",
                                                            "block w-full px-4 py-2 text-left text-sm text-red-600"
                                                        )}
                                                        onClick={
                                                            handleClickDeleteProduction
                                                        }
                                                    >
                                                        {t(
                                                            "line_production.button_confirm_delete_production"
                                                        )}
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        </form>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    )}
                </div>

                <div className="flex flex-1 gap-7">
                    <LazyLoadImage
                        src={productionDetail.meta.image}
                        width={88}
                        height={128}
                        className="object-contain"
                        placeholderSrc={placeholderImage}
                    />
                    <div className="flex-1">
                        <div className="mb-4 flex flex-col items-center justify-between sm:flex-row">
                            <div className="mb-2 font-display font-semibold text-blue-600">
                                {productionDetail.title}
                            </div>
                            <div className="flex flex-col">
                                <div className="flex flex-row gap-2">
                                    <div className="font-display text-sm">
                                        <span className="font-display  text-blue-600">
                                            {t("line_production.label.type")}
                                        </span>{" "}
                                        {productionDetail.dataset.type}
                                    </div>
                                    <div className="font-display text-sm">
                                        <span className="font-display  text-blue-600">
                                            {t(
                                                "line_production.label.provider"
                                            )}
                                        </span>{" "}
                                        {productionDetail.dataset.provider}
                                    </div>
                                </div>
                                {renderMetaProduction()}
                            </div>
                        </div>

                        <div className="font-ligh hidden text-justify font-display sm:block">
                            {productionDetail.content}
                        </div>
                        {addToPrompt && (
                            <div className="flex flex-col gap-3">
                                {renderAlreadyPrompt()}
                                <div className="flex">
                                    <Menu
                                        as="div"
                                        className="relative inline-block text-left"
                                    >
                                        <div>
                                            <Menu.Button
                                                onClick={() => {
                                                    setShowError(false);
                                                    setShowSucces(false);
                                                }}
                                                className={clsx(
                                                    "mt-3 flex items-center gap-1 rounded p-1",
                                                    listPromptsEmptyProductions?.length ===
                                                        0
                                                        ? "bg-gray-200 text-gray-900"
                                                        : "border-2 border-solid border-blue-600 bg-blue-600 text-white"
                                                )}
                                            >
                                                <span className="sr-only">
                                                    {t(
                                                        "line_production.open_options"
                                                    )}
                                                </span>
                                                {t(
                                                    "line_production.add_to_prompt"
                                                )}
                                                <FolderPlusIcon className="h-6 w-6" />
                                            </Menu.Button>
                                        </div>

                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute left-0 z-10 mt-2 w-80 origin-top-right rounded-md bg-white shadow-lg focus:outline-none">
                                                <div className="py-1">
                                                    {listPromptsEmptyProductions?.length ? (
                                                        listPromptsEmptyProductions.map(
                                                            (item, index) => {
                                                                return (
                                                                    <Menu.Item
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {({
                                                                            active,
                                                                        }) => (
                                                                            <button
                                                                                type="submit"
                                                                                className={classNames(
                                                                                    active
                                                                                        ? "bg-gray-100 text-gray-900"
                                                                                        : "text-gray-700",
                                                                                    "block w-full px-4 py-2 text-left text-sm "
                                                                                )}
                                                                                onClick={() =>
                                                                                    apiFetch
                                                                                        .call(
                                                                                            `/prompts/${item.id}?production_id=${productionDetail.id}`,
                                                                                            "PUT"
                                                                                        )
                                                                                        .then(
                                                                                            (
                                                                                                rep
                                                                                            ) => {
                                                                                                if (
                                                                                                    rep.success
                                                                                                ) {
                                                                                                    setShowSucces(
                                                                                                        true
                                                                                                    );
                                                                                                } else {
                                                                                                    setShowError(
                                                                                                        true
                                                                                                    );
                                                                                                }
                                                                                                listPrompts.refetch();
                                                                                            }
                                                                                        )
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </button>
                                                                        )}
                                                                    </Menu.Item>
                                                                );
                                                            }
                                                        )
                                                    ) : (
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <button
                                                                    type="submit"
                                                                    className={classNames(
                                                                        "block w-full bg-gray-100 px-4 py-2 text-left text-sm text-gray-900 "
                                                                    )}
                                                                >
                                                                    {t(
                                                                        "line_production.message_no_prompt_to_add_production"
                                                                    )}
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                    )}
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                    {showSucces && (
                                        <SuccesInfo
                                            description={t(
                                                "line_production.succes_info"
                                            )}
                                        />
                                    )}
                                    {showError && (
                                        <ErrorInfo
                                            description={t(
                                                "error_info.default"
                                            )}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="font-ligh block border-b-2 border-solid border-gray-200 px-5 pb-5 text-justify font-display sm:hidden">
                {productionDetail.content}
            </div>
        </>
    );
};

export default LineProduction;
