import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import apiFetch from "../../utils/apiFetch";
import { CONSTANT_USE_QUERY } from "../../utils/useFetch";

const ProviderList = () => {
    const { t } = useTranslation();
    const dataProviderList = useQuery(CONSTANT_USE_QUERY.PROVIDER_LIST, () =>
        apiFetch.call("/providers/stats")
    );
    if (dataProviderList.isLoading || dataProviderList.isError) {
        return <></>;
    }
    return (
        <div className="mt-10">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h2 className="text-base font-semibold leading-6 text-gray-900">
                        {t("provider_list.title.label")}
                    </h2>
                    <p className="mt-2 text-sm text-gray-700">
                        {t("provider_list.subtitle.label")}
                    </p>
                </div>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-1 -my-2 overflow-x-auto">
                    <div className="inline-block min-w-full px-1 py-2 align-middle">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                        >
                                            {t(
                                                "provider_list.table.title.name"
                                            )}
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            {t(
                                                "provider_list.table.title.type"
                                            )}
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            {t(
                                                "provider_list.table.title.productions"
                                            )}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {dataProviderList?.data?.map(
                                        (provider, index) => (
                                            <tr key={index}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {provider.provider}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {provider.type}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {provider.count}
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ProviderList;
