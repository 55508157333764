import {
    ArrowRightOnRectangleIcon,
    DocumentTextIcon,
    HomeIcon,
    ListBulletIcon,
    PlusCircleIcon,
} from "@heroicons/react/20/solid";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useLogto } from "@logto/react";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import md5 from "md5";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { userDetailAtom } from "../../App";
import background from "../../assets/images/background.jpg";
import apiFetch from "../../utils/apiFetch";
import { CONSTANT_USE_QUERY } from "../../utils/useFetch";
import LogoCCEyes from "../partials/LogoCCEyes";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
const navigation = [
    {
        name: "navigation.label.dashboard",
        href: "/",
        checkCurrent: ["/"],
        icon: HomeIcon,
    },
    {
        name: "navigation.label.create_prompt",
        href: "/create-prompt",
        checkCurrent: ["/create-prompt"],
        icon: PlusCircleIcon,
    },
    {
        name: "navigation.label.prompts_list",
        href: "/prompts",
        checkCurrent: ["/prompts"],
        icon: ListBulletIcon,
    },
    {
        name: "navigation.label.browse_poductions",
        href: "/production-list",
        checkCurrent: ["/production-list"],
        icon: ListBulletIcon,
    },
    {
        name: "navigation.label.cceyes_doc",
        href: "https://docs.cceyes.eu/",
        external: true,
        icon: DocumentTextIcon,
    },
];

const Navigation = () => {
    const { t } = useTranslation();
    const user = useAtomValue(userDetailAtom);
    const history = useHistory();
    const [currentLink, setCurrentLink] = useState(window.location.pathname);

    const { signOut } = useLogto();

    const logout = () => signOut(window.location.origin);
    const dataQueryPrompts = useQuery(CONSTANT_USE_QUERY.PROMPT_LIST, () =>
        apiFetch.call("/prompts")
    );
    useEffect(() => {
        return history.listen((location) => {
            setCurrentLink(location.pathname);
        });
    }, [history]);

    const renderNumberPrompt = () => {
        if (
            !dataQueryPrompts.isLoading &&
            !dataQueryPrompts.isError &&
            dataQueryPrompts?.data?.length > 0
        ) {
            return (
                <div className="rounded-xl bg-white bg-opacity-80 px-1 text-blue-600">
                    {dataQueryPrompts?.data?.length}
                </div>
            );
        }
        return <></>;
    };

    return (
        <section className=" relative h-full w-full overflow-hidden bg-blue-600">
            <img
                alt=""
                width="2245"
                height="1636"
                className="absolute left-1/2 top-1/2 max-w-none translate-x-[-44%] translate-y-[-42%]"
                src={background}
            />
            <div className="relative flex h-full grow flex-col gap-y-5 overflow-y-auto  px-6 ring-1 ring-white/10 ">
                <div className="flex h-16 shrink-0 items-center font-display text-xl text-white">
                    <LogoCCEyes className="mr-4 inline h-12 w-12" />{" "}
                    {t("navigation.cceyes.title")}
                </div>
                <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                            <ul className="-mx-2 space-y-1">
                                {navigation.map((item) => {
                                    if (item.external) {
                                        return (
                                            <li key={item.name}>
                                                <a
                                                    href={item.href}
                                                    className="flex gap-x-3 rounded-md p-2 font-display text-sm font-semibold leading-6 text-white hover:bg-white hover:bg-opacity-80 hover:text-blue-600"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <item.icon
                                                        className="h-6 w-6 shrink-0"
                                                        aria-hidden="true"
                                                    />
                                                    {t(item.name)}
                                                </a>
                                            </li>
                                        );
                                    }
                                    return (
                                        <li key={item.name}>
                                            <Link
                                                to={item.href}
                                                className={classNames(
                                                    item.href === currentLink
                                                        ? "bg-white bg-opacity-80 font-display text-blue-600"
                                                        : "font-display text-white hover:bg-white hover:bg-opacity-80 hover:text-blue-600",
                                                    "flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                                )}
                                            >
                                                <item.icon className="h-6 w-6 shrink-0" />
                                                {t(item.name)}{" "}
                                                {item.href === "/prompts" &&
                                                    renderNumberPrompt()}
                                            </Link>
                                            {item.href === "/prompts" &&
                                                !dataQueryPrompts.isLoading &&
                                                !dataQueryPrompts.isError &&
                                                dataQueryPrompts?.data?.length >
                                                    0 && (
                                                    <ul
                                                        className="mt-1 px-2"
                                                        id="sub-menu-1"
                                                    >
                                                        {dataQueryPrompts?.data
                                                            ?.sort(
                                                                (a, b) =>
                                                                    b.id - a.id
                                                            )
                                                            .slice(0, 5)
                                                            .map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <li
                                                                        className="my-1"
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <Link
                                                                            to={`/prompt/${item.id}`}
                                                                            className={clsx(
                                                                                "ml-9 flex items-center gap-2 rounded-md px-2 py-2 font-display text-sm leading-6",
                                                                                currentLink ===
                                                                                    `/prompt/${item.id}`
                                                                                    ? "bg-white bg-opacity-80 font-display text-blue-600"
                                                                                    : "text-white hover:bg-white hover:bg-opacity-80 hover:text-blue-600"
                                                                            )}
                                                                        >
                                                                            <MagnifyingGlassIcon className="h-4 w-4" />
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            )}
                                                    </ul>
                                                )}
                                        </li>
                                    );
                                })}
                            </ul>
                        </li>
                        <li className="-mx-3 mb-3 mt-auto flex justify-between gap-x-2">
                            <div className="flex items-center gap-x-4 font-display text-sm font-semibold leading-6 text-white">
                                <img
                                    className="h-8 w-8 rounded-full bg-gray-800"
                                    src={
                                        user?.picture ||
                                        `https://www.gravatar.com/avatar/${md5(
                                            user?.email
                                        )}`
                                    }
                                    alt={user?.email}
                                />
                                <span className="overflow-hidden text-ellipsis">
                                    {user?.name}
                                </span>
                            </div>
                            <button
                                className="rounded-md p-2 font-display text-sm font-semibold leading-6 text-white hover:bg-white hover:bg-opacity-80 hover:text-blue-600"
                                onClick={logout}
                                title={t("navigation.label.logout")}
                            >
                                <ArrowRightOnRectangleIcon
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                />
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </section>
    );
};

export default Navigation;
