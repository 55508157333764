import LogoCCEyes from "../partials/LogoCCEyes";
import HeaderAction from "./partials/HeaderAction";
import { useTranslation } from "react-i18next";

const Header = () => {
    const { t } = useTranslation();
    return (
        <header className="py-10">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <nav className="relative z-50 flex justify-between">
                    <div className="flex items-center font-display text-2xl text-slate-900">
                        <LogoCCEyes className="mr-4 inline h-14 w-14" />
                        {t("project.name")}
                    </div>
                    <HeaderAction />
                </nav>
            </div>
        </header>
    );
};
export default Header;
