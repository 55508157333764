import { useLogto } from "@logto/react";
import { useTranslation } from "react-i18next";

const HeaderAction = () => {
    const { t } = useTranslation();
    const { signIn } = useLogto();
    return (
        <div className="flex gap-x-5 md:gap-x-8">
            <div className="flex items-center gap-x-5 ">
                <button
                    className="rounded-full px-8 py-4 text-sm font-semibold text-slate-700 transition duration-300 ease-in-out hover:scale-105 hover:bg-slate-100 hover:text-slate-900"
                    onClick={() =>
                        signIn(window.location.origin + "/oauth/callback")
                    }
                >
                    <span>{t("header_action.login")}</span>
                </button>
            </div>
            <div className="flex items-center gap-x-5 ">
                <button
                    className="group inline-flex transform items-center justify-center rounded-full bg-blue-600 px-8 py-4 font-display text-sm font-semibold text-white transition duration-300 ease-in-out hover:scale-105 hover:bg-sky-300 hover:text-slate-100 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2"
                    onClick={() =>
                        signIn(window.location.origin + "/oauth/callback")
                    }
                >
                    <span>{t("header_action.sign_up")}</span>
                </button>
            </div>
        </div>
    );
};
export default HeaderAction;
