import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholderImage from "../../assets/images/placeholderImage.png";

const LineProductionSmall = ({ productionDetail }) => {
    const { t } = useTranslation();

    const renderMetaProduction = () => {
        const { meta } = productionDetail;

        if (meta) {
            const metaPresent = [];
            ["ratings", "status", "extra"].map((item) => {
                if (meta[item]) metaPresent.push(item);
            });
            // console.log("metaPresent =>", metaPresent);
            if (metaPresent.length)
                return (
                    <p className="font-display text-sm">
                        <span className="font-display font-semibold text-blue-600">
                            {t("line_production.label.meta")}
                        </span>{" "}
                        {metaPresent.join(" / ")}
                    </p>
                );
        }
        return null;
    };

    return (
        <>
            <div className="group relative mt-3 flex items-center px-2 py-1 sm:mt-0 sm:border-b-2 sm:border-solid sm:border-gray-200">
                <div className="flex flex-1 flex-row items-center gap-7">
                    <div>
                        <LazyLoadImage
                            src={productionDetail.meta.image}
                            width={40}
                            height={60}
                            className="my-auto object-contain"
                            placeholderSrc={placeholderImage}
                            wrapperClassName="!flex items-center"
                        />
                    </div>
                    <div className="flex-1">
                        <div className="flex flex-col items-center justify-between sm:flex-row">
                            <div className="mb-2 text-sm font-semibold leading-6 text-gray-900">
                                {productionDetail.title}
                            </div>
                            <div className="flex flex-col">
                                <div className="flex flex-col gap-2 text-end">
                                    <div className="text-sm leading-6 text-gray-900">
                                        <span className="text-sm leading-6 text-blue-600">
                                            {t("line_production.label.type")}
                                        </span>{" "}
                                        {productionDetail.dataset.type}
                                    </div>
                                    <div className="text-sm  leading-6 text-gray-900">
                                        <span className="text-sm  leading-6 text-blue-600">
                                            {t(
                                                "line_production.label.provider"
                                            )}
                                        </span>{" "}
                                        {productionDetail.dataset.provider}
                                    </div>
                                </div>
                                {renderMetaProduction()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LineProductionSmall;
