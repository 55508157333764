import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import searchImage from "../assets/images/searchImage.svg";
import ListProductions from "../components/ListProductions";
import ErrorInfo from "../components/info/ErrorInfo";
import SuccesInfo from "../components/info/SuccesInfo";
import WarnningInfo from "../components/info/WarnningInfo";
import SvgLoading from "../components/partials/SvgLoading";
import TextareaCreatePrompt from "../components/partials/components/TextareaCreatePrompt";
import apiFetch from "../utils/apiFetch";
import { CONSTANT_USE_QUERY } from "../utils/useFetch";
import LayoutPage from "./LayoutPage";

const initialStateresponseApi = {
    error: false,
    succes: false,
};

const CreatePrompt = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const location = useLocation();
    const history = useHistory();
    const [queryPrompt, setQueryPrompt] = useState("");
    const [namePrompt, setNamePrompt] = useState("");
    const [responseApi, setResponseApi] = useState(initialStateresponseApi);
    const [queryPromptValidated, setQueryPromptValidated] = useState("");
    const [editMode, setEditMode] = useState(false);
    const [hasResultsPrompt, setHasResultsPrompt] = useState(false);
    const [nameHasAlereadyUse, setNameHasAlereadyUse] = useState(false);
    const [fetchingCreatePrompt, setFetchingCreatePrompt] = useState(false);
    const [dataProductions, setDataProductions] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const dataQueryPrompts = useQuery(CONSTANT_USE_QUERY.PROMPT_LIST, () =>
        apiFetch.call("/prompts")
    );
    useEffect(() => {
        if (location.pathname.startsWith("/edit-prompt") && id) {
            setDataLoading(true);
            setEditMode(true);
            apiFetch.call(`/prompts/${id}`).then((data) => {
                if (data === "error") {
                    history.push("/prompts");
                    return;
                }
                setEditMode(true);
                setNamePrompt(data.name);
                setQueryPrompt(data.query);
                setQueryPromptValidated(data.query);
                if (data?.productions?.length)
                    setDataProductions(data.productions);
                setDataLoading(false);
            });
        }
    }, []);
    useEffect(() => {
        if (namePrompt?.length && dataQueryPrompts?.data?.length) {
            const hasNameAlreadyUse = dataQueryPrompts.data.find(
                (el) => el.name === namePrompt
            );
            setNameHasAlereadyUse(!!hasNameAlreadyUse);
        }
        return () => {};
    });

    const hadleClickSavePrompt = () => {
        setFetchingCreatePrompt(true);

        apiFetch
            .call("/prompts", "POST", {
                query: queryPromptValidated,
                name: namePrompt,
            })
            .then((data) => {
                if (data === "error") {
                    setResponseApi({
                        ...initialStateresponseApi,
                        error: true,
                    });
                    setFetchingCreatePrompt(false);
                } else {
                    setResponseApi({
                        ...initialStateresponseApi,
                        succes: true,
                    });
                    dataQueryPrompts.refetch();
                    history.push(`/prompt/${data.id}`);
                }
            })
            .catch((err) => {
                setResponseApi({
                    ...initialStateresponseApi,
                    error: true,
                });
                setFetchingCreatePrompt(false);
            });
    };
    const handleClickGeneratePrompt = () => {
        setQueryPromptValidated(queryPrompt);
        setDataLoading(true);
        apiFetch
            .call("/prompts", "POST", { query: queryPrompt }, true)
            .then((data) => {
                setDataLoading(false);
                console.log("data LIST PRODUC =>", data);
                if (data === "error") {
                    setResponseApi({
                        ...initialStateresponseApi,
                        error: true,
                    });
                    setFetchingCreatePrompt(false);
                    return;
                }
                setHasResultsPrompt(true);
                setDataProductions(data?.productions || []);
            })
            .catch((err) => {
                setResponseApi({
                    ...initialStateresponseApi,
                    error: true,
                });
                setFetchingCreatePrompt(false);
            });
    };
    return (
        <LayoutPage>
            <h2 className="mb-16 flex justify-center font-display text-2xl font-semibold">
                {editMode
                    ? t("create_prompt.edit_mode.title", { name: namePrompt })
                    : t("create_prompt.title")}
            </h2>
            {editMode && (
                <div className="mb-2">
                    <WarnningInfo
                        description={t("create_prompt.warning_info")}
                    />
                </div>
            )}
            <TextareaCreatePrompt
                handleChangeQueryPrompt={(e) => {
                    if (e.target.value.length > 1000) return;
                    setQueryPrompt(e.target.value || "");
                }}
                handleChangeNamePrompt={(e) => {
                    setNamePrompt(e.target.value || "");
                }}
                queryPrompt={queryPrompt}
                queryPromptValidated={queryPromptValidated}
                hasResultsPrompt={hasResultsPrompt}
                handleClickGeneratePrompt={handleClickGeneratePrompt}
                namePrompt={namePrompt}
                handleClickSavePrompt={hadleClickSavePrompt}
                nameHasAlereadyUse={nameHasAlereadyUse}
                fetchingCreatePrompt={fetchingCreatePrompt}
                editMode={editMode}
            />
            {responseApi.error && (
                <ErrorInfo description={t("create_prompt.error_info")} />
            )}
            {responseApi.succes && (
                <SuccesInfo description={t("create_prompt.succes_info")} />
            )}
            <div className="relative">
                {dataLoading && (
                    <div className="absolute inset-0 z-50 flex flex-col justify-start gap-4 bg-white bg-opacity-90 p-6 text-center text-gray-500">
                        <p className="text-2xl font-bold">
                            {t("list_production.loading")}
                        </p>
                        <SvgLoading />
                    </div>
                )}
                {queryPromptValidated.length > 0 ||
                dataProductions.length > 0 ? (
                    <>
                        <h2 className="my-2 text-center text-2xl font-bold">
                            {t("create_prompt.prompt_result")}
                        </h2>

                        <ListProductions
                            // querySearch={queryPromptValidated}
                            productionList={dataProductions}
                            // hasResultsPrompt={() => setHasResultsPrompt(true)}
                            showDelete={false}
                        />
                    </>
                ) : (
                    <img
                        className="mx-auto mt-16 flex h-56 w-56 md:h-96 md:w-96 lg:h-72 lg:w-72"
                        src={searchImage}
                        alt={t("create_prompt.magnifying_glass")}
                    />
                )}
            </div>
        </LayoutPage>
    );
};
export default CreatePrompt;
