import React from "react";
import LayoutPage from "../../pages/LayoutPage";
import { generateEmptyArray } from "../../utils/tools";
import PlaceholderBackground from "./PlaceholderBackground";
import PlaceholderProductionLine from "./PlaceholderProductionLine";

const PlaceholderPromptPage = () => {
    return (
        <LayoutPage>
            <div className="flex items-start justify-between">
                <div className="flex flex-col gap-2">
                    <div className="flex gap-2">
                        <PlaceholderBackground className="h-8 w-52" />
                        <PlaceholderBackground className="h-8 w-32" />
                    </div>
                    <div className="flex gap-2">
                        <PlaceholderBackground className="h-6 w-28" />
                        <PlaceholderBackground className="h-6 w-96" />
                    </div>
                </div>
                <div className="flex items-center gap-4">
                    <PlaceholderBackground className="h-8 w-24" />
                    <PlaceholderBackground className="h-8 w-20" />
                </div>
            </div>
            {generateEmptyArray(5).map((k) => (
                <PlaceholderProductionLine key={k} />
            ))}
        </LayoutPage>
    );
};
export default PlaceholderPromptPage;
